import React from "react";
import dynamic from "next/dynamic";
import Chat from "components/Chat/Chat";

import Button from "components/CustomButtons/Button";

import { Dialog, Box, DialogContent } from "@mui/material";
import { useFullUser } from "auth/useUser.js";
import FadeOverlay from "components/Transition/FadeOverlay.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useTranslation from "next-translate/useTranslation";

import { useRenders } from "modules/utils";
import StravaMockupScreen from "strava/StravaMockupScreen";

//import GearOverview from 'components/Charts/GearOverview';
import ProfileHeader from "pages-sections/dashboard/ProfileHeader.js";
import EmailMockup, {
  EmailRecommendationMockup,
} from "components/Mockups/EmailMockup.js";

import {
  getExampleProduct,
  getTagNewGearText,
  getExampleParkrunResults,
  getReviewText,
  getTagProgressText,
  getTagTargetText,
  combineDescriptions,
  getTagStreaksText,
  statsByText,
  /*  getParkrunStatsDescription,
  getParkrunStatsName, */
} from "../../../tagging/tagFactory";

const GearOverview = dynamic(() => import("components/Charts/GearOverview"), {
  ssr: true,
});

export const getExampleContent = (name, user, exampleProps = {}) => {
  switch (name) {
    case "tagNewGear": {
      const exampleProduct = getExampleProduct(user);

      return (
        <StravaMockupScreen
          user={user}
          description={getTagNewGearText(exampleProduct, user)}
        />
      );
    }
    case "tagStreaks": {
      return (
        <StravaMockupScreen
          user={user}
          description={[
            getTagStreaksText(user, "running", { running: { streak: 17 } }),
            statsByText,
          ].join(`
`)}
        />
      );
    }
    case "tagProgressBlock": {
      const description = combineDescriptions(
        getTagProgressText(
          user,
          "running",
          {
            year: { distance: 1032330, elevationGain: 10002 },
            month: { distance: 106223.6, elevationGain: 8344 },
            week: { distance: 30305.1, elevationGain: 234 },
          },
          undefined,
          false
        ),
        getTagTargetText(
          user,
          "running",
          {
            year: { distance: 1032330 },
            //  month: { distance: 32330 },
          },
          new Date(),
          1500,
          "year",
          true
        ),
        null,
        true
      );
      return <StravaMockupScreen user={user} description={description} />;
    }
    case "tagYearTargets": {
      return (
        <StravaMockupScreen
          user={user}
          description={getTagTargetText(
            user,
            "running",
            {
              year: { distance: 1032330 },
              //  month: { distance: 32330 },
            },
            new Date(),
            1500,
            "year",
            true
          )}
        />
      );
    }
    case "tagYearProgress": {
      return (
        <StravaMockupScreen
          user={user}
          description={getTagProgressText(
            user,
            "running",
            {
              year: { distance: 1032330, elevationGain: 10002 },
              month: { distance: 106223.6, elevationGain: 8344 },
              week: { distance: 30305.1, elevationGain: 234 },
            },
            undefined,
            true
          )}
        />
      );
    }
    case "tagReviews": {
      const exampleProduct = getExampleProduct(user);

      return (
        <StravaMockupScreen
          user={user}
          description={getReviewText(exampleProduct, user)}
        />
      );
    }
    case "parkrunStatsBlock":
    case "parkrunStatsSync": {
      const exampleParkrunResults = getExampleParkrunResults(user);

      return (
        <StravaMockupScreen
          user={user}
          name={exampleParkrunResults.name}
          description={exampleParkrunResults.description}
        />
      );
    }

    /* case 'tagTrainingPlanRuns': {
      const exampleTrainingPlanDetails = getTrainingPlanRunsText(user);

      return (
        <StravaMockupScreen
          user={user}
          name={exampleTrainingPlanDetails.name}
          description={exampleTrainingPlanDetails.description}
        />
      );
    } */
    case "statsBlock":
      return <GearOverview limit={3} user={user} noBorder limitAspectRatio />;

    case "priceComparisonBlock": {
      return <EmailMockup user={user} />;
    }

    case "recommendationsBlock":
      return <EmailRecommendationMockup user={user} />;
    /*   <Box sx={{ '& div > *': { textAlign: 'unset !important' } }}>*/
    /*  <Chat
          dummyMessages={user.exampleMessages}
          sizeAndSexProps={{}}
          dummyOnly
          user={user}
        /> */
    /*   </Box> */

    /*     case 'recommendationsBlock':
      return (
        <Recommendations
          user={user}
          initOpenState={true}
          dummyData={user.recommendationsData}
          demoOnly
        />
      ); */

    /* case "gearManagementBlock": {
      const item1 = user.athleteProducts.edges.find(
        (edge) => edge.items?.length
      ).items[0];

      const item2 = user.athleteProducts.edges.find(
        (edge) =>
          edge.items?.length && !edge.items.some((item) => item.id === item1.id)
      ).items[0];
      const defaultsState = [
        {
          item: item1,
          activityType: exampleProps.shoeActivityTypes[0],
        },
        {
          item: item2,
          activityType: exampleProps.shoeActivityTypes[1],
        },
      ];
      return (
        <DefaultsModal
          athlete={user}
          defaultsState={defaultsState}
          {...exampleProps}
          demoOnly
        />
      );
    } */
    case "shareProfileBlock":
      return <ProfileHeader user={user} demoOnly />;
    case "default":
      return null;
  }
};

const FeatureExample = (props) => {
  const { t } = useTranslation();
  const { name } = props;

  const [user] = useFullUser();
  const [open, setOpen] = React.useState(false);
  useRenders("featureExample");

  const content = getExampleContent(name, user);

  if (!content) {
    console.log(
      "requested example which we have no content for | Name:",
      name,
      "props",
      props
    );
    return <div />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        endIcon={<VisibilityIcon fontSize="small" />}
      >
        {t("dashboard:example")}
      </Button>
      <Dialog
        onClose={handleClose}
        TransitionProps={{ onClose: handleClose }}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogContent>{content}</DialogContent>
        <FadeOverlay orientation="horizontal" inProp={true} />
      </Dialog>
    </>
  );
};

export default FeatureExample;
