const customImageLoader = ({ src, width, quality }) => {
  if (src.includes("cdn.geerly.com")) {
    return `${src}?format=auto${
      quality ? `&quality=${quality}` : ""
    }&width=${width}`;
  }

  // For local images, still need to handle the width parameter somehow
  if (src.startsWith("/_next/")) {
    // Either handle width for local images:
    return `${src}?w=${width}`;
    // Or if you truly want no transformation:
    // return src;  // But this will keep giving the warning
  }

  // For all other cases
  return src;
};

export default customImageLoader;
